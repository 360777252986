import { useQuery } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";
import { IFarmerOrder } from "../types/orders";

const API = process.env.REACT_APP_API;

const useFarmerOrders = (fairId: number | null) => {
  const fetch = useFetch();

  return useQuery({
    queryKey: ["farmerOrders", fairId],
    queryFn: () => {
      return fetch(`${API}/api/farmer-orders?fair=${fairId}`, {
        method: "GET",
      }) as Promise<IFarmerOrder[]>;
    },
    onError: (error) => {
      console.log("onError", error);
    },
    enabled: fairId !== null,
  });
};

export default useFarmerOrders;
