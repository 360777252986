import {
  Box,
  Checkbox,
  Paper,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import useOrders from "../../services/useOrders";
import Filters from "./Filters";
import Order from "./Order";
import { FilterHandler, Option } from "../../types/core";
import { IOrder } from "../../types/orders";
import { useContext, useState } from "react";
import { findIndex, orderBy } from "lodash";
import usePrint from "../../hooks/usePrint";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import StatusIcon, { STATUSES } from "../../components/StatusIcon";
import { FairContext } from "../../contexts/FairContext";
import { IFairContext } from "../../types/fair";
import FairNavigation from "../../components/FairNavigation";
import { getOrderStatus, getTotal } from "./utils";
import useUpdateOrderStatus from "../../services/useUpdateOrderStatus";

const Orders = () => {
  const { fair } = useContext(FairContext) as IFairContext;
  const [item, setItem] = useState<Option | null>(null);
  const [status, setStatus] = useState<string>("TODOS");
  const [urbanPartner, setUrbanPartner] = useState<Option | null>(null);
  const { data: orders, isLoading: isLoadingOrders } = useOrders(
    fair ? fair.id : null,
  );
  const { printing, print } = usePrint();
  const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
  const { mutate } = useUpdateOrderStatus();

  if (!fair) {
    return <FairNavigation />;
  }

  if (printing) {
    const hasRefrigerated = orders?.find((order) =>
      order.items.find((item) => item.product.base_product.refrigerated),
    );

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            fontSize: "2rem",
            fontWeight: "700",
            lineHeight: "2.5rem",
          }}
        >
          Pedidos
        </Box>
        {orders?.map((order) => {
          const status = getOrderStatus(order.items);

          if (status === "CANCELED") {
            return null;
          }

          const total = getTotal(order);
          const sortedItems = orderBy(
            order.items,
            [
              "product.base_product.stack_order",
              "product.producer.name",
              "product.base_product.name",
            ],
            ["desc", "asc", "asc"],
          );

          return (
            <Box
              key={order.id}
              sx={{
                border: "1px solid black",
              }}
            >
              <Box
                sx={{
                  borderBottom: "1px solid black",
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  padding: "0.5rem",
                }}
              >
                {order.urban_partner.name}
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns:
                    "min-content min-content auto auto max-content",
                }}
              >
                {sortedItems.map((item) => {
                  if (item.status === "CANCELED") {
                    return null;
                  }

                  return (
                    <Box
                      key={item.id}
                      sx={{
                        display: "contents",
                        "&:nth-of-type(even) > div": {
                          backgroundColor: "#dddddd",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          gridColumn: "1",
                          minHeight: "1.25rem",
                          padding: "0.5rem",
                        }}
                      >
                        {item.product.base_product.refrigerated ? "R" : null}
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          gridColumn: "2",
                          minHeight: "1.25rem",
                          padding: "0.5rem",
                        }}
                      >
                        {item.amount}
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          gridColumn: "3",
                          minHeight: "1.25rem",
                          padding: "0.5rem",
                        }}
                      >
                        {item.product.base_product.name}
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          gridColumn: "4",
                          minHeight: "1.25rem",
                          padding: "0.5rem",
                        }}
                      >
                        {item.product.producer.name}
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid black",
                          gridColumn: "5",
                          minHeight: "1.25rem",
                          padding: "0.5rem",
                        }}
                      >
                        R$ {(item.amount * item.price).toFixed(2)}
                      </Box>
                    </Box>
                  );
                })}
                {parseFloat(order.delivery_fee) > 0 ? (
                  <Box
                    sx={{
                      display: "contents",
                      "&:nth-child(even) > div": {
                        backgroundColor: "#dddddd",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: "1px solid black",
                        borderRight: "1px solid black",
                        gridColumn: "1 / 5",
                        minHeight: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      Frete
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid black",
                        gridColumn: "5",
                        minHeight: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      R$ {order.delivery_fee}
                    </Box>
                  </Box>
                ) : null}
              </Box>
              <Box
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  padding: "0.5rem",
                  textAlign: "right",
                }}
              >
                Total: R$ {total.toFixed(2)}
              </Box>
            </Box>
          );
        })}
        {hasRefrigerated ? (
          <Box
            sx={{
              fontSize: "2rem",
              fontWeight: "700",
              lineHeight: "2.5rem",
            }}
          >
            Refrigerados
          </Box>
        ) : null}
        {orders?.map((order) => {
          const status = getOrderStatus(order.items);
          const orderHasRefrigerated = order.items.find(
            (item) => item.product.base_product.refrigerated,
          );

          if (status === "CANCELED" || !orderHasRefrigerated) {
            return null;
          }

          return (
            <Box
              key={order.id}
              sx={{
                border: "1px solid black",
              }}
            >
              <Box
                sx={{
                  borderBottom: "1px solid black",
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  padding: "0.5rem",
                }}
              >
                {order.urban_partner.name}
              </Box>
              {order.items
                .filter((item) => item.product.base_product.refrigerated)
                .map((item) => (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "min-content auto",
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: "1px solid black",
                        borderRight: "1px solid black",
                        gridColumn: "1",
                        minHeight: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      {item.amount}
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid black",
                        borderRight: "1px solid black",
                        gridColumn: "2",
                        minHeight: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      {item.product.base_product.name}
                    </Box>
                  </Box>
                ))}
            </Box>
          );
        })}
      </Box>
    );
  }

  const handleItemFilter: FilterHandler = (_, newValue) => {
    setItem(newValue);
  };

  const handleUrbanPartnerFilter: FilterHandler = (_, newValue) => {
    setUrbanPartner(newValue);
  };

  const handleStatusFilter = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const toggle = (id: number) => () => {
    const index = selectedOrders.findIndex((orderId) => orderId === id);
    if (index === -1) {
      setSelectedOrders([...selectedOrders, id]);
    } else {
      setSelectedOrders([
        ...selectedOrders.slice(0, index),
        ...selectedOrders.slice(index + 1),
      ]);
    }
  };

  let filteredOrders =
    orders &&
    orders.reduce((acc, order) => {
      let appendOrder = true;
      if (
        item !== null &&
        findIndex(
          order.items,
          (orderItem) => orderItem.product.id === item.value,
        ) === -1
      ) {
        appendOrder = false;
      }

      const orderStatus = getOrderStatus(order.items);

      if (status !== "TODOS" && orderStatus !== status) {
        appendOrder = false;
      }

      if (
        urbanPartner !== null &&
        order.urban_partner.id !== urbanPartner.value
      ) {
        appendOrder = false;
      }

      if (appendOrder) {
        acc.push(order);
      }

      return acc;
    }, [] as IOrder[]);

  const allChecked = filteredOrders?.every((order) => {
    const index = selectedOrders.findIndex((orderId) => orderId === order.id);
    return index !== -1;
  });

  const toggleAllChecked = () => {
    if (allChecked) {
      setSelectedOrders([]);
    } else {
      if (filteredOrders) {
        setSelectedOrders(filteredOrders.map((order) => order.id));
      }
    }
  };

  const selectedStatus = filteredOrders
    ? filteredOrders.reduce(
        (acc, order) => {
          const notSelected =
            selectedOrders.findIndex(
              (selectedOrder) => selectedOrder === order.id,
            ) === -1;
          if (notSelected) {
            return acc;
          }

          const status = getOrderStatus(order.items);

          if (acc === null) {
            return status;
          } else if (acc === status) {
            return acc;
          }

          return "MIXED";
        },
        null as null | keyof typeof STATUSES,
      )
    : null;

  const setSelectedStatus = (status: keyof typeof STATUSES) => {
    if (fair) {
      selectedOrders.forEach((orderId) => {
        mutate({ orderId, status });
      });
    }
  };

  let allowedStatuses: (keyof typeof STATUSES)[] = [];
  if (selectedStatus === "ORDERED" || selectedStatus === "FINISHED") {
    allowedStatuses = ["PAID"];
  } else if (selectedStatus === "PAID") {
    allowedStatuses = ["ORDERED", "FINISHED"];
  }

  return (
    <Box
      sx={{
        margin: "1.5rem auto",
        maxWidth: "900px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography gutterBottom variant="h4" component="h1">
          Pedidos da semana
        </Typography>
        <IconButton
          size="large"
          color="inherit"
          aria-label="print"
          onClick={print}
        >
          <PrintIcon />
        </IconButton>
      </Box>
      <Filters
        orders={orders}
        item={item}
        onItemFilter={handleItemFilter}
        status={status}
        onUrbanPartnerFilter={handleUrbanPartnerFilter}
        urbanPartner={urbanPartner}
        onStatusFilter={handleStatusFilter}
        loading={isLoadingOrders}
      />
      <FairNavigation />
      {!fair || isLoadingOrders ? (
        <div>Loading</div>
      ) : !filteredOrders ? null : (
        <Paper sx={{ width: "100%" }}>
          <Box sx={{ display: "flex" }}>
            <Checkbox checked={allChecked} onChange={toggleAllChecked} />
            {selectedStatus ? (
              <StatusIcon
                setStatus={setSelectedStatus}
                status={selectedStatus}
                statuses={allowedStatuses}
              />
            ) : null}
          </Box>
          {filteredOrders.map((order: IOrder) => {
            const checked =
              selectedOrders.findIndex((orderId) => orderId === order.id) !==
              -1;

            return (
              <Order
                checked={checked}
                fairId={fair.id}
                forceExpand={printing}
                key={order.id}
                order={order}
                toggle={toggle(order.id)}
              />
            );
          })}
        </Paper>
      )}
    </Box>
  );
};

export default Orders;
