import { useMutation, useQueryClient } from "@tanstack/react-query";

import useFetch from "../lib/useFetch";

const API = process.env.REACT_APP_API;

const useRemoveItem = () => {
  const fetch = useFetch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => {
      return fetch(`${API}/api/order-item/remove`, {
        method: "DELETE",
        body: { id },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["farmerOrders"] });
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });
};

export default useRemoveItem;
