import { useQuery } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";
import { IOrder } from "../types/orders";

const API = process.env.REACT_APP_API;

const useOrders = (fairId: number | null) => {
  const fetch = useFetch();

  return useQuery({
    queryKey: ["orders", fairId],
    queryFn: () => {
      return fetch(`${API}/api/orders?fair=${fairId}`, {
        method: "GET",
      }) as Promise<IOrder[]>;
    },
    onError: (error) => {
      console.log("onError", error);
    },
    enabled: fairId !== null,
  });
};

export default useOrders;
