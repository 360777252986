import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  href: string;
}

const NavItem: FC<Props> = ({ children, href }) => {
  return (
    <ListItem>
      <ListItemButton component="a" href={href}>
        <ListItemText>{children}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
