import { useEffect, useState } from "react";

function usePrint() {
  const [printing, setPrinting] = useState(false);

  useEffect(() => {
    const afterprint = () => {
      setPrinting(false);
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, [setPrinting]);

  useEffect(() => {
    if (printing) {
      setTimeout(() => {
        window.print();
      }, 0);
    }
  }, [printing]);

  const print = () => {
    setPrinting(true);
  };

  return { printing, print };
}

export default usePrint;
