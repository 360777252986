import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";
import PrintIcon from "@mui/icons-material/Print";
import usePrint from "../../hooks/usePrint";
import React, { useEffect, useState } from "react";
import useFairs from "../../services/useFairs";
import useOrders from "../../services/useOrders";
import { IOrder } from "../../types/orders";
import {
  DndContext,
  closestCorners,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import DeliveryRow from "./DeliveryRow";
import { keyBy } from "lodash";

const filterOrders = (orders?: IOrder[]) =>
  orders
    ? orders.filter(
        (order) =>
          !order.items.every((orderItem) => orderItem.status === "CANCELED"),
      )
    : [];

const containsAllItems = (array1: number[], array2: number[]) => {
  return array1.every((item) => array2.includes(item));
};

const formatPhone = (phone: string) =>
  `(${phone.slice(3, 5)}) ${phone.slice(5, 10)}-${phone.slice(10, 14)}`;

const DeliveryRoute = () => {
  const [fair] = useState(0);
  const { data: fairs } = useFairs();
  const { data: orders, isLoading: isLoadingOrders } = useOrders(
    fairs ? fairs[fair].id : null,
  );
  const { printing, print } = usePrint();
  const [ordersSorting, setOrdersSorting] = useState<number[]>([]);

  useEffect(() => {
    const filteredOrders = filterOrders(orders);
    if (filteredOrders.length !== 0) {
      const ordersSortingFromStorage = sessionStorage.getItem("ordersSorting");
      if (
        ordersSortingFromStorage !== null &&
        containsAllItems(
          JSON.parse(ordersSortingFromStorage),
          filteredOrders.map((order) => order.id),
        )
      ) {
        setOrdersSorting(JSON.parse(ordersSortingFromStorage));
      } else {
        const ordersSorting = filteredOrders.map((order) => order.id);
        setOrdersSorting(ordersSorting);
        sessionStorage.setItem("ordersSorting", JSON.stringify(ordersSorting));
      }
    }
  }, [orders, setOrdersSorting]);

  const handleDragEnd = (event: { active: any; over: any }) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const activeIndex = ordersSorting.findIndex(
        (orderId) => orderId === active.id,
      );
      const overIndex = ordersSorting.findIndex(
        (orderId) => orderId === over.id,
      );

      const newOrdersSorting = arrayMove(ordersSorting, activeIndex, overIndex);
      setOrdersSorting(newOrdersSorting);
      sessionStorage.setItem("ordersSorting", JSON.stringify(newOrdersSorting));
    }
  };

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const ordersById = keyBy(orders, "id");

  if (printing) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            border: "1px solid black",
          }}
        >
          {ordersSorting.map((orderId: number) => {
            const order = ordersById[orderId];
            return (
              <Box
                key={orderId}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "max-content auto max-content",
                  "&:nth-of-type(even) > div": {
                    backgroundColor: "#dddddd",
                  },
                }}
              >
                <Box
                  sx={{
                    border: "1px solid black",
                    gridColumn: "1",
                    minHeight: "1.25rem",
                    padding: "0.5rem",
                  }}
                >
                  {order.urban_partner.name}
                </Box>
                <Box
                  sx={{
                    border: "1px solid black",
                    gridColumn: "2",
                    minHeight: "1.25rem",
                    padding: "0.5rem",
                  }}
                >
                  {order.address}
                </Box>
                <Box
                  sx={{
                    border: "1px solid black",
                    gridColumn: "3",
                    minHeight: "1.25rem",
                    padding: "0.5rem",
                  }}
                >
                  {formatPhone(order.urban_partner.phone)}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  return (
    <div className={styles.page}>
      {!printing ? (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography gutterBottom variant="h4" component="h1">
            Rotas de Entrega
          </Typography>
          <IconButton
            size="large"
            color="inherit"
            aria-label="print"
            onClick={print}
          >
            <PrintIcon />
          </IconButton>
        </Box>
      ) : null}

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem 0",
        }}
      ></Box>

      {isLoadingOrders ? (
        <div>Loading</div>
      ) : !orders ? null : (
        <div style={{ height: 400, width: "100%" }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
          >
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Endereço</TableCell>
                    <TableCell>Telefone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <SortableContext
                    items={ordersSorting}
                    strategy={verticalListSortingStrategy}
                  >
                    {ordersSorting.map((orderId) => (
                      <React.Fragment key={orderId}>
                        <DeliveryRow order={ordersById[orderId]} />
                      </React.Fragment>
                    ))}
                  </SortableContext>
                </TableBody>
              </Table>
            </TableContainer>
          </DndContext>
        </div>
      )}
    </div>
  );
};

export default DeliveryRoute;
