import { useMutation } from "@tanstack/react-query";
import { IAuthContext } from "../types/auth";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import useFetch from "../lib/useFetch";

const API = process.env.REACT_APP_API;

const useLogin = (username: string, password: string) => {
  const { setAuthTokens } = useContext(AuthContext) as IAuthContext;
  const navigate = useNavigate();
  const fetch = useFetch();

  return useMutation({
    mutationKey: ["login"],
    mutationFn: () => {
      return fetch(`${API}/api/token/`, {
        method: "POST",
        body: { username, password },
      });
    },
    onSuccess: (data) => {
      if (data) {
        setAuthTokens(data);
        navigate("/products", { replace: true });
      }
    },
    onError: (error) => {
      console.log("onError", error);
    },
  });
};

export default useLogin;
