import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";
import { STATUSES } from "../components/StatusIcon";

const API = process.env.REACT_APP_API;

const useUpdateGroupedOrderItemsStatus = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: ({
      farmerOrderId,
      productId,
      status,
    }: {
      farmerOrderId: number;
      productId: number;
      status: keyof typeof STATUSES;
    }) => {
      return fetch(`${API}/api/order-item/update-group-status`, {
        method: "PATCH",
        body: { farmer_order_id: farmerOrderId, product_id: productId, status },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["farmerOrders"] });
    },
  });
};

export default useUpdateGroupedOrderItemsStatus;
