import { FC } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { sortBy } from "lodash";

import { FilterHandler, Option } from "../../../types/core";
import { IProduct } from "../../../types/products";

interface FiltersProps {
  products: IProduct[];
  baseProduct: Option | null;
  onBaseProductFilter: FilterHandler;
  producer: Option | null;
  onProducerFilter: FilterHandler;
  stock: string;
  onStockFilter: (event: SelectChangeEvent) => void;
}

const Filters: FC<FiltersProps> = ({
  products,
  baseProduct,
  onBaseProductFilter,
  producer,
  onProducerFilter,
  stock,
  onStockFilter,
}) => {
  const baseProducts = products.reduce((acc, product) => {
    const newBaseProduct = {
      label: `${product.base_product.name} - id: ${product.base_product.id}`,
      value: product.base_product.id,
    };

    if (
      !acc.some((baseProduct) => baseProduct.value === newBaseProduct.value)
    ) {
      acc.push(newBaseProduct);
    }

    return acc;
  }, [] as Option[]);

  const sortedBaseProducts = sortBy(baseProducts, "label");

  const producerOptions = products.reduce(
    (acc: Array<{ label: string; value: null | number }>, product) => {
      const newProducer = {
        label: product.producer.name,
        value: product.producer.id,
      };

      if (!acc.some((producer) => producer.value === newProducer.value)) {
        acc.push(newProducer);
      }

      return acc;
    },
    [] as Option[],
  );

  const sortedProducerOption = sortBy(producerOptions, "label");

  return (
    <Box sx={{ display: "flex", gap: "1rem", margin: "1rem 0" }}>
      <Autocomplete
        value={baseProduct}
        onChange={onBaseProductFilter}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disablePortal
        options={sortedBaseProducts}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Busque um produto" />
        )}
      />
      <Autocomplete
        value={producer}
        onChange={onProducerFilter}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disablePortal
        options={sortedProducerOption}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Agricultor" />}
      />
      <FormControl sx={{ minWidth: 300 }}>
        <InputLabel>Estoque</InputLabel>
        <Select value={stock} onChange={onStockFilter} label="Estoque">
          <MenuItem value="todos">Todos</MenuItem>
          <MenuItem value="em estoque">Em estoque</MenuItem>
          <MenuItem value="baixo estoque">Baixo estoque</MenuItem>
          <MenuItem value="sem estoque">Sem estoque</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default Filters;
