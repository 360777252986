import { useMutation, useQueryClient } from "@tanstack/react-query";

import useFetch from "../lib/useFetch";

const API = process.env.REACT_APP_API;

const useReplaceItem = () => {
  const fetch = useFetch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      cost,
      fair,
      farmer,
      orderItems,
      product,
    }: {
      cost: string;
      fair: number;
      farmer: number;
      orderItems: number[];
      product: number;
    }) => {
      return fetch(`${API}/api/order-item/replace-item`, {
        method: "POST",
        body: {
          cost,
          fair,
          farmer,
          order_items: orderItems,
          product,
        },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["farmerOrders"] });
    },
  });
};

export default useReplaceItem;
