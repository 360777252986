import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import useFarmerOrders from "../../services/useFarmerOrders";
import FarmerOrder from "./FarmerOrder";
import Filters from "./Filters";
import { useContext, useState } from "react";
import { FilterHandler, Option } from "../../types/core";
import { IFarmerOrder, IGroupedItem } from "../../types/orders";
import { findIndex, sortBy } from "lodash";
import usePrint from "./../../hooks/usePrint";
import PrintIcon from "@mui/icons-material/Print";
import StatusIcon, { STATUSES } from "../../components/StatusIcon";
import useFarmerOrder from "../../services/useFarmerOrder";
import { FairContext } from "../../contexts/FairContext";
import { IFairContext } from "../../types/fair";
import FairNavigation from "../../components/FairNavigation";
import { getTotal } from "./utils";

const FarmerOrders = () => {
  const { fair } = useContext(FairContext) as IFairContext;
  const [farmer, setFarmer] = useState<Option | null>(null);
  const [item, setItem] = useState<Option | null>(null);
  const [status, setStatus] = useState<string>("TODOS");
  const { data: farmerOrders, isLoading: isLoadingFarmerOrders } =
    useFarmerOrders(fair ? fair.id : null);
  const { printing, print } = usePrint();
  const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
  const { mutate } = useFarmerOrder();

  if (!fair) {
    return <FairNavigation />;
  }

  if (printing) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        {farmerOrders?.map((farmerOrder) => {
          const groupedNotCanceledItems = farmerOrder.items.reduce(
            (acc, item) => {
              if (item.status === "CANCELED") {
                return acc;
              }

              if (acc[item.product.id]) {
                acc[item.product.id].amount += item.amount;
                acc[item.product.id].items.push(item);
              } else {
                acc[item.product.id] = {
                  amount: item.amount,
                  farmerOrder,
                  items: [item],
                  product: item.product,
                };
              }

              return acc;
            },
            {} as { [index: number]: IGroupedItem },
          );

          const sortedItems = sortBy(
            Object.values(groupedNotCanceledItems),
            "product.base_product.name",
          );

          if (sortedItems.length === 0) {
            return null;
          }

          const total = getTotal(farmerOrder.items);

          return (
            <Box
              key={farmerOrder.id}
              sx={{
                border: "1px solid black",
              }}
            >
              <Box
                sx={{
                  borderBottom: "1px solid black",
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  padding: "0.5rem",
                }}
              >
                {farmerOrder.farmer.name}
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns:
                    "min-content auto auto max-content max-content",
                }}
              >
                {sortedItems.map((item) => (
                  <Box
                    key={item.product.id}
                    sx={{
                      display: "contents",
                      "&:nth-of-type(even) > div": {
                        backgroundColor: "#dddddd",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: "1px solid black",
                        borderRight: "1px solid black",
                        gridColumn: "1",
                        minHeight: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      {item.amount}
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid black",
                        borderRight: "1px solid black",
                        gridColumn: "2",
                        minHeight: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      {item.product.base_product.name}
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid black",
                        borderRight: "1px solid black",
                        gridColumn: "3",
                        minHeight: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      {item.product.producer.name}
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid black",
                        borderRight: "1px solid black",
                        gridColumn: "4",
                        minHeight: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      R$ {item.product.cost}
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid black",
                        gridColumn: "5",
                        minHeight: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      R$ {(item.amount * item.product.cost).toFixed(2)}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  padding: "0.5rem",
                  textAlign: "right",
                }}
              >
                Total: R$ {total.toFixed(2)}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  }

  const handleItemFilter: FilterHandler = (_, newValue) => {
    setItem(newValue);
  };

  const handleFarmerFilter: FilterHandler = (_, newValue) => {
    setFarmer(newValue);
  };

  const handleStatusFilter = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const toggle = (id: number) => () => {
    const index = selectedOrders.findIndex((orderId) => orderId === id);
    if (index === -1) {
      setSelectedOrders([...selectedOrders, id]);
    } else {
      setSelectedOrders([
        ...selectedOrders.slice(0, index),
        ...selectedOrders.slice(index + 1),
      ]);
    }
  };

  const filteredOrders =
    farmerOrders &&
    farmerOrders.reduce((acc, farmerOrder) => {
      let appendOrder = true;
      if (
        item !== null &&
        findIndex(
          farmerOrder.items,
          (farmerOrderItem) => farmerOrderItem.product.id === item.value,
        ) === -1
      ) {
        appendOrder = false;
      }

      if (status !== "TODOS" && farmerOrder.status !== status) {
        appendOrder = false;
      }

      if (farmer !== null && farmerOrder.farmer.id !== farmer.value) {
        appendOrder = false;
      }

      if (appendOrder) {
        acc.push(farmerOrder);
      }

      return acc;
    }, [] as IFarmerOrder[]);

  const allChecked = filteredOrders?.every((order) => {
    const index = selectedOrders.findIndex((orderId) => orderId === order.id);
    return index !== -1;
  });

  const toggleAllChecked = () => {
    if (allChecked) {
      setSelectedOrders([]);
    } else {
      if (filteredOrders) {
        setSelectedOrders(filteredOrders.map((order) => order.id));
      }
    }
  };

  const selectedStatus = filteredOrders
    ? filteredOrders.reduce(
        (acc, order) => {
          const notSelected =
            selectedOrders.findIndex(
              (selectedOrder) => selectedOrder === order.id,
            ) === -1;
          if (notSelected) {
            return acc;
          }

          if (acc === null) {
            return order.status;
          } else if (acc === order.status) {
            return acc;
          }

          return "MIXED";
        },
        null as null | keyof typeof STATUSES,
      )
    : null;

  const setSelectedStatus = (status: string) => {
    if (fair) {
      selectedOrders.forEach((farmerOrderId) => {
        mutate({ fairId: fair.id, farmerOrderId, status });
      });
    }
  };

  let allowedStatuses: (keyof typeof STATUSES)[] = [];
  if (selectedStatus === "ORDERED") {
    allowedStatuses = ["PAID"];
  } else if (selectedStatus === "PAID") {
    allowedStatuses = ["ORDERED"];
  }

  return (
    <Box
      sx={{
        margin: "1.5rem auto",
        maxWidth: "900px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography gutterBottom variant="h4" component="h1">
          Encomendas da semana
        </Typography>
        <IconButton
          size="large"
          color="inherit"
          aria-label="print"
          onClick={print}
        >
          <PrintIcon />
        </IconButton>
      </Box>
      <div>
        <Filters
          farmer={farmer}
          farmerOrders={farmerOrders}
          item={item}
          onFarmerFilter={handleFarmerFilter}
          onItemFilter={handleItemFilter}
          onStatusFilter={handleStatusFilter}
          status={status}
        />
      </div>
      <FairNavigation />
      {!fair || isLoadingFarmerOrders ? (
        <div>Loading</div>
      ) : !filteredOrders ? null : (
        <Paper sx={{ width: "100%" }}>
          <Box sx={{ display: "flex" }}>
            <Checkbox checked={allChecked} onChange={toggleAllChecked} />
            {selectedStatus ? (
              <StatusIcon
                setStatus={setSelectedStatus}
                status={selectedStatus || ""}
                statuses={allowedStatuses}
              />
            ) : null}
          </Box>
          {filteredOrders.map((farmerOrder) => {
            const checked =
              selectedOrders.findIndex(
                (orderId) => orderId === farmerOrder.id,
              ) !== -1;

            return (
              <FarmerOrder
                checked={checked}
                fairId={fair.id}
                farmerOrder={farmerOrder}
                forceExpand={printing}
                key={farmerOrder.id}
                toggle={toggle(farmerOrder.id)}
              />
            );
          })}
        </Paper>
      )}
    </Box>
  );
};

export default FarmerOrders;
