import { useQuery } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";
import { IFarmer } from "../types/farmers";

const API = process.env.REACT_APP_API;

const useFarmers = () => {
  const fetch = useFetch();

  return useQuery({
    queryKey: ["farmers"],
    queryFn: () => {
      return fetch(`${API}/api/farmers`, {
        method: "GET",
      }) as Promise<IFarmer[]>;
    },
    onError: (error) => {
      console.log("onError", error);
    },
  });
};

export default useFarmers;
