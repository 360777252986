import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { IOrder } from "../../types/orders";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface Props {
  order: IOrder;
}

const formatPhone = (phone: string) =>
  `(${phone.slice(3, 5)}) ${phone.slice(5, 10)}-${phone.slice(10, 14)}`;

const DeliveryRow: FC<Props> = ({ order }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: order.id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <TableRow ref={setNodeRef} {...attributes} {...listeners} sx={style}>
      <TableCell align="right">
        <DragIndicatorIcon />
      </TableCell>
      <TableCell>{order.urban_partner.name}</TableCell>
      <TableCell>{order.address}</TableCell>
      <TableCell>{formatPhone(order.urban_partner.phone)}</TableCell>
    </TableRow>
  );
};

export default DeliveryRow;
