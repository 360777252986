import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";
import { IOrderItem } from "../types/orders";

const API = process.env.REACT_APP_API;

const useFarmerOrder = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: ({ farmerOrderId, items, status }) => {
      return fetch(`${API}/api/farmer-order/${farmerOrderId}`, {
        method: "PATCH",
        body: { items, status },
      });
    },
    onMutate: async (variables: {
      fairId: number;
      farmerOrderId: number;
      items?: IOrderItem[];
      status?: string;
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["farmerOrders", variables.fairId],
      });

      const previousFarmerOrders = queryClient.getQueryData([
        "farmerOrders",
        variables.fairId,
      ]);

      queryClient.setQueryData(
        ["farmerOrders", variables.fairId],
        (oldFarmerOrders: any) => {
          const index = oldFarmerOrders.findIndex(
            (order: any) => order.id === variables.farmerOrderId,
          );

          const items = variables.items
            ? variables.items.slice()
            : oldFarmerOrders[index].items.slice();
          if (variables.items) {
            oldFarmerOrders[index].items.forEach((oldItem: IOrderItem) => {
              const orderItemIndex = items.findIndex(
                (orderItem: IOrderItem) => orderItem.id === oldItem.id,
              );
              if (orderItemIndex === -1) {
                items.push(oldItem);
              }
            });
          }

          const newFarmerOrders = [
            ...oldFarmerOrders.slice(0, index),
            {
              ...oldFarmerOrders[index],
              items,
              status: variables.status
                ? variables.status
                : oldFarmerOrders[index].status,
            },
            ...oldFarmerOrders.slice(index + 1),
          ];

          return newFarmerOrders;
        },
      );

      return { previousFarmerOrders };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["farmerOrders"], context?.previousFarmerOrders);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["farmerOrders"] });
    },
  });
};

export default useFarmerOrder;
