import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { IAuthContext } from "../types/auth";
import { useNavigate } from "react-router-dom";

interface Options {
  method: "DELETE" | "GET" | "PATCH" | "POST";
  body?: any;
}

const API = process.env.REACT_APP_API;

const useFetch = () => {
  const { tokens, setAuthTokens } = useContext(AuthContext) as IAuthContext;
  const navigate = useNavigate();

  const makeAPICall = async (
    url: string,
    access: string | null,
    options?: Options,
  ) => {
    const headers: RequestInit["headers"] = {
      "Content-Type": "application/json",
    };

    if (access !== null) {
      headers["Authorization"] = `Bearer ${access}`;
    }

    return await fetch(url, {
      method: options && options.method ? options.method : "GET",
      headers,
      body: options && options.body ? JSON.stringify(options.body) : undefined,
    });
  };

  const makeRefreshCall = async () => {
    return await fetch(`${API}/api/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: tokens.refresh,
      }),
    });
  };

  return async (url: string, options?: Options) => {
    const response = await makeAPICall(url, tokens.access, options);
    if (response.status === 200 || response.status === 201) {
      return await response.json();
    }

    if (response.status === 401) {
      const { code } = await response.json();
      if (code === "token_not_valid") {
        const refreshResponse = await makeRefreshCall();

        if (refreshResponse.status === 200) {
          const { access } = await refreshResponse.json();
          setAuthTokens({ access, refresh: tokens.refresh });

          const secondTryResponse = await makeAPICall(url, access, options);
          if (secondTryResponse.status === 200) {
            return await secondTryResponse.json();
          }
        } else {
          setAuthTokens({ access: null, refresh: null });
          navigate("/login", { replace: true });
        }
      }
    } else {
      throw await response.json();
    }
  };
};

export default useFetch;
