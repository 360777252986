import { FC, ReactNode, createContext, useEffect, useState } from "react";

import { IFairContext } from "../types/fair";
import useFairs from "../services/useFairs";

export const FairContext = createContext<IFairContext | null>(null);

interface Props {
  children: ReactNode;
}

const FairProvider: FC<Props> = ({ children }) => {
  const [index, setIndex] = useState(-1);
  const { data: fairs, isLoading } = useFairs();

  useEffect(() => {
    if (fairs && fairs.length > 0) {
      setIndex(0);
    }
  }, [fairs, setIndex]);

  const hasNextFair = !!fairs && index > 0 && fairs.length > 0;
  const hasPreviousFair =
    !!fairs && index < fairs.length - 1 && fairs.length > 0;

  const nextFair = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const previousFair = () => {
    if (fairs && index < fairs.length - 1) {
      setIndex(index + 1);
    }
  };

  return (
    <FairContext.Provider
      value={{
        fair: fairs && index !== -1 ? fairs[index] : null,
        hasNextFair,
        hasPreviousFair,
        isLoading,
        nextFair,
        previousFair,
      }}
    >
      {children}
    </FairContext.Provider>
  );
};

export default FairProvider;
