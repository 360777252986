import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { FC, MouseEvent, useState } from "react";
import {
  Cancel,
  Check,
  Paid,
  PlusOne,
  ShoppingBasket,
} from "@mui/icons-material";

export const STATUSES = {
  ORDERED: {
    icon: <ShoppingBasket />,
    label: "Pedido",
  },
  PAID: {
    icon: <Paid />,
    label: "Pago",
  },
  FINISHED: {
    icon: <Check />,
    label: "Concluído",
  },
  CANCELED: {
    icon: <Cancel />,
    label: "Cancelado",
  },
  MIXED: {
    icon: <PlusOne />,
    label: "Diferentes status",
  },
};

interface Props {
  setStatus: (status: keyof typeof STATUSES) => void;
  status: keyof typeof STATUSES | null;
  statuses: (keyof typeof STATUSES)[];
}

const StatusIcon: FC<Props> = ({ setStatus, status, statuses }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const statusOpen = Boolean(anchorEl);

  if (!status) {
    return null;
  }

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const handleStatusOpen = (event: MouseEvent<HTMLButtonElement>) => {
    if (statuses.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleStatusChange = (status: keyof typeof STATUSES) => () => {
    setStatus(status);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip title={STATUSES[status].label}>
        <IconButton onClick={handleStatusOpen}>
          {STATUSES[status].icon}
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={statusOpen} onClose={handleStatusClose}>
        {statuses.map((status) => (
          <MenuItem
            key={status}
            onClick={handleStatusChange(status)}
            sx={{ gap: "8px" }}
          >
            {STATUSES[status].icon}
            <span>{STATUSES[status].label}</span>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default StatusIcon;
