import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import { FC, useState } from "react";
import { IGroupedItem } from "../../../types/orders";
import StatusIcon, { STATUSES } from "../../../components/StatusIcon";
import { Option } from "../../../types/core";
import useFarmers from "../../../services/useFarmers";
import useReplaceItem from "../../../services/useReplaceItem";
import useUpdateGroupedOrderItemsStatus from "../../../services/useUpdateGroupedOrderItemsStatus";

interface Props {
  fairId: number;
  groupedItem: IGroupedItem;
}

const getStatus = (groupedItem: IGroupedItem) => {
  const status = groupedItem.items.reduce(
    (acc, item) => {
      if (acc === null) {
        return item.status;
      }

      if (acc === item.status) {
        return acc;
      }

      return "MIXED";
    },
    null as keyof typeof STATUSES | null,
  );

  if (status !== null) {
    return status;
  }

  return null;
};

const GroupedOrderItem: FC<Props> = ({ fairId, groupedItem }) => {
  const theme = useTheme();
  const { mutate } = useUpdateGroupedOrderItemsStatus();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: farmers } = useFarmers();
  const [farmer, setFarmer] = useState<Option | null>(null);
  const [cost, setCost] = useState("");
  const { mutate: replaceItem } = useReplaceItem();

  const handleStatusChange = (status: keyof typeof STATUSES) => {
    if (status === "MIXED") {
      return;
    }

    if (status === "CANCELED") {
      setDialogOpen(true);

      return;
    }

    mutate({
      farmerOrderId: groupedItem.farmerOrder.id,
      productId: groupedItem.product.id,
      status,
    });
  };

  const status = getStatus(groupedItem);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleNotSubstituteItem = () => {
    mutate({
      farmerOrderId: groupedItem.farmerOrder.id,
      productId: groupedItem.product.id,
      status: "CANCELED",
    });
    setDialogOpen(false);
  };

  const handleSubstituteItem = () => {
    if (farmer && farmer.value) {
      replaceItem({
        cost,
        fair: fairId,
        farmer: farmer.value,
        orderItems: groupedItem.items.map((item) => item.id),
        product: groupedItem.product.id,
      });
    }
    setDialogOpen(false);
  };

  let allowedStatuses: (keyof typeof STATUSES)[] = [];
  if (getStatus(groupedItem) === "PAID") {
    allowedStatuses = ["CANCELED"];
  } else if (getStatus(groupedItem) === "CANCELED") {
    allowedStatuses = ["PAID"];
  }

  return (
    <TableRow
      sx={{
        backgroundColor: groupedItem.items.every(
          (item) => item.status === "CANCELED",
        )
          ? theme.palette.error.light
          : groupedItem.items.some((item) => item.status === "CANCELED")
            ? theme.palette.warning.light
            : "transparent",
      }}
    >
      {status !== null ? (
        <TableCell>
          <StatusIcon
            setStatus={handleStatusChange}
            status={status}
            statuses={allowedStatuses}
          />
        </TableCell>
      ) : null}
      <TableCell align="right">{groupedItem.amount}</TableCell>
      <TableCell>{groupedItem.product.base_product.name}</TableCell>
      <TableCell align="right">
        {(groupedItem.amount * groupedItem.product.cost).toFixed(2)}
      </TableCell>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        PaperProps={{
          sx: { overflow: "visible" },
        }}
      >
        <DialogTitle>Alterar item do pedido</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            Deseja alterar a origem do produto?
          </DialogContentText>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Autocomplete
              value={farmer}
              onChange={(e, newValue) => {
                setFarmer(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disablePortal
              options={
                farmers
                  ? farmers.map((farmer) => ({
                      label: farmer.name,
                      value: farmer.id,
                    }))
                  : []
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Buscar produtor" />
              )}
            />
            <TextField
              label="Custo"
              onChange={(e) => {
                setCost(e.target.value);
              }}
              value={cost}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNotSubstituteItem}>Não substituir item</Button>
          <Button onClick={handleSubstituteItem}>Substituir</Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
};

export default GroupedOrderItem;
