import { useQuery } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";

const API = process.env.REACT_APP_API;

const useProducts = () => {
  const fetch = useFetch();

  return useQuery({
    queryKey: ["products"],
    queryFn: () => {
      return fetch(`${API}/api/products`, {
        method: "GET",
      });
    },
    onError: (error) => {
      console.log("onError", error);
    },
  });
};

export default useProducts;
