import { useQuery } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";
import { IFair } from "../types/orders";

const API = process.env.REACT_APP_API;

const useFairs = () => {
  const fetch = useFetch();

  return useQuery({
    queryKey: ["fairs"],
    queryFn: () => {
      return fetch(`${API}/api/fairs`, {
        method: "GET",
      }) as Promise<IFair[]>;
    },
    onError: (error) => {
      console.log("onError", error);
    },
  });
};

export default useFairs;
