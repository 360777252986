import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { FC, useState } from "react";
import useProducts from "../../../services/useProducts";
import { IProduct } from "../../../types/products";
import { Option } from "../../../types/core";
import useAddOrderItem from "../../../services/useAddOrderItem";

interface Props {
  orderId: number;
}

const AddItem: FC<Props> = ({ orderId }) => {
  const { mutate } = useAddOrderItem(orderId);
  const { data: products } = useProducts();
  const [amount, setAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState<null | Option>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addItem = () => {
    if (product && product.value) {
      mutate({ amount, productId: product.value });
      handleClose();
    }
  };

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <Add />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { overflow: "visible" },
        }}
      >
        <DialogTitle>Adicionar item ao pedido</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecione o produto e a quantidade abaixo:
          </DialogContentText>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Autocomplete
              value={product}
              onChange={(e, newValue) => {
                setProduct(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disablePortal
              options={
                products
                  ? products.map((product: IProduct) => ({
                      label: `${product.base_product.name} - ${product.producer.name}`,
                      value: product.id,
                    }))
                  : []
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Buscar produto" />
              )}
            />
            <TextField
              label="Quantidade"
              onChange={(e) => {
                setAmount(parseInt(e.target.value));
              }}
              value={amount}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={addItem}>Adicionar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddItem;
