import { Box, IconButton } from "@mui/material";
import { useContext } from "react";
import { FairContext } from "../../contexts/FairContext";
import { IFairContext } from "../../types/fair";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import dayjs from "dayjs";

const FairNavigation = () => {
  const {
    fair,
    isLoading,
    hasNextFair,
    hasPreviousFair,
    nextFair,
    previousFair,
  } = useContext(FairContext) as IFairContext;

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (!fair) {
    return null;
  }

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        margin: "1rem 0",
      }}
    >
      <IconButton
        color="primary"
        disabled={!hasPreviousFair}
        onClick={previousFair}
      >
        <ArrowBack />
      </IconButton>
      {dayjs(fair.date).format("DD/MM/YYYY")}
      <IconButton color="primary" disabled={!hasNextFair} onClick={nextFair}>
        <ArrowForward />
      </IconButton>
    </Box>
  );
};

export default FairNavigation;
