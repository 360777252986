import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import { IOrderItem, OrderItemStatus } from "../../../types/orders";
import { FC, useState } from "react";
import StatusIcon, { STATUSES } from "../../../components/StatusIcon";
import useOrderItem from "../../../services/useOrderItem";
import { Delete } from "@mui/icons-material";
import useRemoveItem from "../../../services/useRemoveItem";

interface Props {
  fairId: number;
  item: IOrderItem;
  orderStatus: OrderItemStatus | "MIXED" | null;
}

const OrderItem: FC<Props> = ({ fairId, item, orderStatus }) => {
  const { mutate } = useOrderItem();
  const { mutate: removeItem } = useRemoveItem();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleStatusChange = (status: string) => {
    mutate({ fairId, orderItemId: item.id, order: item.order, status });
  };

  let allowedStatuses: (keyof typeof STATUSES)[] = [];
  if (item.status === "ORDERED" && orderStatus === "MIXED") {
    allowedStatuses = ["PAID"];
  } else if (item.status === "PAID") {
    allowedStatuses = ["CANCELED"];
  } else if (item.status === "CANCELED") {
    allowedStatuses = ["PAID"];
  }

  const handleRemove = () => {
    removeItem(item.id);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableRow
      sx={{
        backgroundColor:
          item.status === "CANCELED"
            ? theme.palette.error.light
            : "transparent",
      }}
    >
      <TableCell>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <StatusIcon
            setStatus={handleStatusChange}
            status={item.status}
            statuses={allowedStatuses}
          />
          {item.status === "ORDERED" && (
            <IconButton onClick={handleOpen}>
              <Delete />
            </IconButton>
          )}
        </Box>
      </TableCell>
      <TableCell align="right">{item.amount}</TableCell>
      <TableCell>
        {item.product.base_product.name} - {item.product.producer.name}
      </TableCell>
      <TableCell align="right">
        {(item.amount * item.price).toFixed(2)}
      </TableCell>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Você tem certeza que deseja remover esse item do pedido?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Não</Button>
          <Button onClick={handleRemove} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
};

export default OrderItem;
