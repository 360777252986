import { AppBar, Drawer, List, Toolbar, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./styles.module.css";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { IAuthContext } from "../../types/auth";
import { useNavigate } from "react-router-dom";
import NavButton from "./NavButton";
import NavItem from "./NavItem";

const NAV_ITEMS = [
  {
    href: "/products",
    title: "Produtos",
  },
  {
    href: "/orders",
    title: "Pedidos",
  },
  {
    href: "/farmer-orders",
    title: "Encomendas",
  },
  {
    href: "/delivery-route",
    title: "Rota de entregas",
  },
];

const NavigationBar = () => {
  const { clearAuthTokens, tokens } = useContext(AuthContext) as IAuthContext;
  const navigate = useNavigate();

  useEffect(() => {
    if (tokens.access === null && tokens.refresh === null) {
      navigate("/login", { replace: true });
    }
  }, [navigate, tokens]);

  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    clearAuthTokens();
  };

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.hideOnPrint}>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <NavButton edge="start" onClick={toggle}>
            <MenuIcon />
          </NavButton>
          <div className={styles.title}>
            <div className={styles.logo_container}>
              <img alt="logo" className={styles.logo} src="/logo.png" />
            </div>
            <Typography>Portal da semeadora</Typography>
          </div>
          <NavButton edge="end" onClick={handleLogout}>
            <LogoutIcon />
          </NavButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={toggle}>
        <List>
          {NAV_ITEMS.map((item) => (
            <NavItem key={item.href} href={item.href}>
              {item.title}
            </NavItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default NavigationBar;
