import { FC } from "react";
import { IFarmerOrder } from "../../../types/orders";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { findIndex, sortBy } from "lodash";
import { FilterHandler, Option } from "../../../types/core";

interface Props {
  farmer: Option | null;
  farmerOrders?: IFarmerOrder[];
  item: Option | null;
  onFarmerFilter: FilterHandler;
  onItemFilter: FilterHandler;
  onStatusFilter: (event: SelectChangeEvent) => void;
  status: string;
}

const Filters: FC<Props> = ({
  farmer,
  farmerOrders = [],
  item,
  onFarmerFilter,
  onItemFilter,
  onStatusFilter,
  status,
}) => {
  const items = farmerOrders.reduce((acc, farmerOrder) => {
    farmerOrder.items.forEach((item) => {
      const option = {
        label: item.product.base_product.name,
        value: item.product.id,
      };
      if (findIndex(acc, option) === -1) {
        acc.push(option);
      }
    });

    return acc;
  }, [] as Option[]);

  const sortedItems = sortBy(items, "label");

  const farmers = farmerOrders.reduce((acc, farmerOrder) => {
    const option = {
      label: farmerOrder.farmer.name,
      value: farmerOrder.farmer.id,
    };
    if (findIndex(acc, option) === -1) {
      acc.push(option);
    }

    return acc;
  }, [] as Option[]);

  const sortedFarmers = sortBy(farmers, "label");

  return (
    <Box sx={{ display: "flex", gap: "1rem", margin: "1rem 0" }}>
      <Autocomplete
        value={item}
        onChange={onItemFilter}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disablePortal
        options={sortedItems}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar item de encomenda" />
        )}
      />
      <Autocomplete
        value={farmer}
        onChange={onFarmerFilter}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disablePortal
        options={sortedFarmers}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar agricultor" />
        )}
      />
      <FormControl sx={{ minWidth: 300 }}>
        <InputLabel>Status</InputLabel>
        <Select value={status} onChange={onStatusFilter} label="Status">
          <MenuItem value="TODOS">Todos</MenuItem>
          <MenuItem value="ORDERED">Em aberto</MenuItem>
          <MenuItem value="PAID">Pago</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default Filters;
