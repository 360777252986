import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";
import useLogin from "../../services/useLogin";
import { IAuthContext } from "../../types/auth";
import styles from "./styles.module.css";
import { Button, TextField, Typography } from "@mui/material";

const Login = () => {
  const navigate = useNavigate();
  const { tokens } = useContext(AuthContext) as IAuthContext;
  const [form, setForm] = useState({ email: "", senha: "" });
  const { isLoading, mutate } = useLogin(form.email, form.senha);

  useEffect(() => {
    if (tokens.access !== null && tokens.refresh !== null) {
      navigate("/products", { replace: true });
    }
  }, [navigate, tokens]);

  const handleChange =
    (key: "email" | "senha") => (e: ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        [key]: e.target.value,
      });
    };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate();
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Typography variant="h5" component="h1">
          Olá, semeadora!
        </Typography>
        <Typography variant="body1" component="h1">
          Faça seu login para começar a administrar sua semente!
        </Typography>
        <form className={styles.form} onSubmit={handleSubmit}>
          <TextField
            label="E-mail"
            onChange={handleChange("email")}
            value={form.email}
          />
          <TextField
            label="Senha"
            onChange={handleChange("senha")}
            type="password"
            value={form.senha}
          />
          <Button
            disabled={isLoading}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Entrar
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
