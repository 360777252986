import FairProvider from "../../contexts/FairContext";
import NavigationBar from "../Navigation";
import { Outlet } from "react-router-dom";

const Root = () => {
  return (
    <div>
      <FairProvider>
        <NavigationBar />
        <Outlet />
      </FairProvider>
    </div>
  );
};

export default Root;
