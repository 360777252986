import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";

const API = process.env.REACT_APP_API;

const useRemoveProduct = (productId: number) => {
  const fetch = useFetch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetch(`${API}/api/product/${productId}`, {
        method: "DELETE",
      });
    },
    onError: (error) => {
      console.log("onError", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};

export default useRemoveProduct;
