import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";
import { STATUSES } from "../components/StatusIcon";

const API = process.env.REACT_APP_API;

const useUpdateOrderStatus = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: ({
      orderId,
      status,
    }: {
      orderId: number;
      status: keyof typeof STATUSES;
    }) => {
      return fetch(`${API}/api/order/update-status`, {
        method: "PATCH",
        body: { id: orderId, status },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });
};

export default useUpdateOrderStatus;
