import { useMutation, useQueryClient } from "@tanstack/react-query";

import useFetch from "../lib/useFetch";

const API = process.env.REACT_APP_API;

const useAddOrderItem = (orderId: number) => {
  const fetch = useFetch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      amount,
      productId,
    }: {
      amount: number;
      productId: number;
    }) => {
      return fetch(`${API}/api/order-item/add`, {
        method: "POST",
        body: {
          amount,
          order_id: orderId,
          product_id: productId,
        },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });
};

export default useAddOrderItem;
