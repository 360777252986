import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";

const API = process.env.REACT_APP_API;

const useOrderItem = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: ({
      orderItemId,
      order,
      status,
    }: {
      orderItemId: number;
      order: number;
      status: string;
    }) => {
      return fetch(`${API}/api/order-item/${orderItemId}`, {
        method: "PATCH",
        body: { status },
      });
    },
    onMutate: async ({
      fairId,
      orderItemId,
      order,
      status,
    }: {
      fairId: number;
      orderItemId: number;
      order: number;
      status: string;
    }) => {
      await queryClient.cancelQueries({ queryKey: ["orders", fairId] });

      const previousOrders = queryClient.getQueryData(["orders", fairId]);

      queryClient.setQueryData(["orders", fairId], (oldOrders: any) => {
        const orderIndex = oldOrders.findIndex(
          (oldOrder: any) => oldOrder.id === order,
        );
        const itemIndex = oldOrders[orderIndex].items.findIndex(
          (oldItem: any) => oldItem.id === orderItemId,
        );

        const newOrders = [
          ...oldOrders.slice(0, orderIndex),
          {
            ...oldOrders[orderIndex],
            items: [
              ...oldOrders[orderIndex].items.slice(0, itemIndex),
              {
                ...oldOrders[orderIndex].items[itemIndex],
                status,
              },
              ...oldOrders[orderIndex].items.slice(itemIndex + 1),
            ],
          },
          ...oldOrders.slice(orderIndex + 1),
        ];

        return newOrders;
      });

      return { previousOrders };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["orders"], context?.previousOrders);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });
};

export default useOrderItem;
