import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
} from "@mui/material";
import { FilterHandler, Option } from "../../../types/core";
import { IOrder } from "../../../types/orders";
import { FC } from "react";
import { findIndex, sortBy } from "lodash";

interface Props {
  orders?: IOrder[];
  item: Option | null;
  onItemFilter: FilterHandler;
  status: string;
  onStatusFilter: (event: SelectChangeEvent) => void;
  urbanPartner: Option | null;
  onUrbanPartnerFilter: FilterHandler;
  loading?: boolean;
}

const Filters: FC<Props> = ({
  orders = [],
  item,
  onItemFilter,
  status,
  onStatusFilter,
  urbanPartner,
  onUrbanPartnerFilter,
  loading = false,
}) => {
  if (loading) {
    return (
      <Box sx={{ display: "flex", gap: "1rem", margin: "1rem 0" }}>
        <Skeleton height={56} width="33%" variant="rounded" />
        <Skeleton height={56} width="33%" variant="rounded" />
        <Skeleton height={56} width="33%" variant="rounded" />
      </Box>
    );
  }

  const items = orders.reduce(
    (acc, order) => {
      order.items.forEach((item) => {
        const option = {
          label: `${item.product.base_product.name} - ${item.product.producer.name}`,
          value: item.product.id,
        };
        if (findIndex(acc, option) === -1) {
          acc.push(option);
        }
      });

      return acc;
    },
    [] as { label: string; value: number }[],
  );

  const sortedItems = sortBy(items, "label");

  const urbanPartners = orders.reduce(
    (acc, order) => {
      const option = {
        label: order.urban_partner.name,
        value: order.urban_partner.id,
      };
      if (findIndex(acc, option) === -1) {
        acc.push(option);
      }

      return acc;
    },
    [] as { label: string; value: number }[],
  );

  const sortedUrbanPartners = sortBy(urbanPartners, "label");

  return (
    <Box sx={{ display: "flex", gap: "1rem", margin: "1rem 0" }}>
      <Autocomplete
        value={item}
        onChange={onItemFilter}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disablePortal
        options={sortedItems}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar item de pedido" />
        )}
      />
      <Autocomplete
        value={urbanPartner}
        onChange={onUrbanPartnerFilter}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disablePortal
        options={sortedUrbanPartners}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar parceira urbana" />
        )}
      />
      <FormControl sx={{ minWidth: 300 }}>
        <InputLabel>Status</InputLabel>
        <Select value={status} onChange={onStatusFilter} label="Status">
          <MenuItem value="TODOS">Todos</MenuItem>
          <MenuItem value="ORDERED">Em aberto</MenuItem>
          <MenuItem value="PAID">Pago</MenuItem>
          <MenuItem value="FINISHED">Finalizado</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default Filters;
