import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Login from "./pages/Login";
import Root from "./components/Root";
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import FarmerOrders from "./pages/FarmerOrders";
import DeliveryRoute from "./pages/DeliveryRoute/index";
import AuthProvider from "./contexts/AuthContext";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        element: <Products />,
        path: "products",
      },
      {
        element: <Orders />,
        path: "orders",
      },
      {
        element: <FarmerOrders />,
        path: "farmer-orders",
      },
      {
        element: <DeliveryRoute />,
        path: "delivery-route",
      },
    ],
  },
]);

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#447737",
    },
    secondary: {
      main: "#9fd236",
      dark: "#82a221",
      light: "#d1ea76",
    },
    background: {
      default: "#f5f2e5",
      paper: "#ffffff",
    },
    text: {
      secondary: "#2f5326",
      disabled: "#2f5326",
    },
    success: {
      main: "#4ddc8c",
    },
    error: {
      main: "#f75e5e",
    },
    warning: {
      main: "#f79f57",
    },
    info: {
      main: "#6ebee8",
    },
    divider: "rgba(68,119,55,0.24)",
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
