import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { IFarmerOrder, IGroupedItem } from "../../../types/orders";
import { FC, useState } from "react";
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { sortBy } from "lodash";
import GroupedOrderItem from "../GroupedOrderItem";
import StatusIcon from "../../../components/StatusIcon";
import useFarmerOrder from "../../../services/useFarmerOrder";
import { getTotal } from "../utils";

interface FarmerOrderProps {
  checked: boolean;
  fairId: number;
  farmerOrder: IFarmerOrder;
  forceExpand: boolean;
  toggle: () => void;
}

const FarmerOrder: FC<FarmerOrderProps> = ({
  checked,
  fairId,
  forceExpand,
  farmerOrder,
  toggle,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { mutate } = useFarmerOrder();

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const setStatus = (status: string) => {
    mutate({ fairId, farmerOrderId: farmerOrder.id, status });
  };

  const groupedItems = farmerOrder.items.reduce(
    (acc, item) => {
      if (acc[item.product.id]) {
        acc[item.product.id].amount += item.amount;
        acc[item.product.id].items.push(item);
      } else {
        acc[item.product.id] = {
          amount: item.amount,
          farmerOrder,
          items: [item],
          product: item.product,
        };
      }

      return acc;
    },
    {} as { [index: number]: IGroupedItem },
  );

  const total = getTotal(farmerOrder.items);

  const sortedItems = sortBy(
    Object.values(groupedItems),
    "product.base_product.name",
  );

  return (
    <>
      <ListItem disablePadding>
        <Checkbox checked={checked} onChange={toggle} />
        <StatusIcon
          setStatus={setStatus}
          status={farmerOrder.status}
          statuses={["ORDERED", "PAID"]}
        />
        <ListItemButton onClick={handleToggle}>
          <Typography sx={{ flex: "1 1 auto" }}>
            {farmerOrder.farmer.name}
          </Typography>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={expanded || forceExpand}>
        <List component="div" disablePadding>
          <ListItem>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Qtd.</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell align="right">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedItems.map((groupedItem) => (
                    <GroupedOrderItem
                      fairId={fairId}
                      key={groupedItem.product.id}
                      groupedItem={groupedItem}
                    />
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell align="right" colSpan={3}>
                      Total
                    </TableCell>
                    <TableCell align="right">{total.toFixed(2)}</TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};

export default FarmerOrder;
