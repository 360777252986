import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetch from "../lib/useFetch";

const API = process.env.REACT_APP_API;

const useProduct = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: ({ productId, product }) => {
      return fetch(`${API}/api/product/${productId}`, {
        method: "PATCH",
        body: product,
      });
    },
    onMutate: async (variables: {
      productId: number;
      product: {
        cost: number;
        price: number;
        stock: number;
        unlimited_stock: boolean;
      };
    }) => {
      await queryClient.cancelQueries({ queryKey: ["products"] });

      const previousProducts = queryClient.getQueryData(["products"]);

      queryClient.setQueryData(["products"], (oldProducts: any) => {
        const index = oldProducts.findIndex(
          (product: any) => product.id === variables.productId,
        );

        const newProducts = [
          ...oldProducts.slice(0, index),
          {
            ...oldProducts[index],
            ...variables.product,
          },
          ...oldProducts.slice(index + 1),
        ];

        return newProducts;
      });

      return { previousProducts };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["products"], context?.previousProducts);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};

export default useProduct;
